import React from "react";
import {graphql} from "gatsby";

import Layout from "../containers/layout";
import Container from "../components/container/container";
import SEO from "../components/seo";
import BlockContent from "../components/block-content";
import {mapEdgesToNodes} from "../lib/helpers";


import {responsiveTitle1} from "../components/typography.module.css";

export const query = graphql`
  query ContactPageQuery {
    settings: allSanitySiteSettings {
      edges {
        node {
          _rawContact
        }
      }
    }
  }
`;

const ContactPage = (props) => {
  const {data} = props;
  const contactDetails = data && data.settings && mapEdgesToNodes(data.settings);
  const _rawContact = contactDetails[0]._rawContact;
  return (
    <Layout>
      <SEO title="Contact"/>
      <Container>
        <h1 className={responsiveTitle1}>Contact</h1>
        {_rawContact && <BlockContent blocks={_rawContact || []}/>}
      </Container>
    </Layout>
  );
};

export default ContactPage;
